<!--  -->
<template>
   
  <div class="topic-details">
    <p style="font-size: 30px; text-align: center">{{ VariantName }}</p>
    <el-card class="active" ref="barparent">
      <el-card class="box-card-left">
        <el-scrollbar style="height: 100%">
          <div>
            <h2 style="font-size: 18px; margin-bottom: 20px">题目描述</h2>
            <ul>
              <li
                v-for="(item, i) in descriptions"
                :key="i"
                :class="item.descriptionType == 6 ? 'class3' : ''"
              >
                {{ descriptionType[item.variantDescriptionValue] }}
                <div class="des_p">
                  <i v-html="item.description"></i>
                </div>
                <!-- <b v-html="item.description" class="des"></b> -->
              </li>
              <el-divider></el-divider>
              <h2 style="font-size: 18px; margin-bottom: 20px">
                溯源《标准答案》
              </h2>
              <el-tree :data="treedata" :props="defaultProps"  default-expand-all>
                <div class="custom-tree-node" slot-scope="{ node, data }">
                  <p>{{ node.label }}</p>
                  <!-- <div v-if="data.descriptions" @click="onTree(node, data)">
                    <p>{{ data.label }}</p>
                  </div> -->
                  <div
                    v-if="data.descriptions"
                    v-for="(m, i) in data.descriptions"
                    :key="i"
                  >
                    <span v-html="m.description"></span>
                  </div>
                </div>
              </el-tree>
            </ul>
          </div>
        </el-scrollbar>
      </el-card>
      <el-card class="box-card-right">
        <el-scrollbar class="scrollbar">
          <el-card class="topic" v-for="(ite, i) in questions" :key="i">
            <p class="topic_serial">
              <span>{{ ite.caseTopicSerial }}</span>
              <span v-if="ite.variantSubjectValue == 1">【单选题】</span>
              <span v-if="ite.variantSubjectValue == 2">【多选题】</span>
            </p>
            <el-scrollbar class="scrollbar">
              <p v-html="ite.caseTopic"></p>
              <div class="topic_con">
                <el-radio-group
                  v-model="ite.answer"
                  style="width: 100%"
                  v-if="ite.variantSubjectValue == 1"
                >
                  <div
                    style="width: 100%"
                    class="option"
                    v-for="(item, index) in ite.list"
                    :key="index"
                  >
                    <el-radio :label="item.sn" style="padding-top: 15px">
                      <div style="display: flex">
                        <span class="sn">{{ item.sn }}：</span>
                        <span
                          v-html="item.choiceContent"
                          class="span_p"
                        ></span></div
                    ></el-radio>
                  </div>
                </el-radio-group>
                <el-checkbox-group
                  v-model="ite.answer"
                  v-if="ite.variantSubjectValue == 2"
                  style="width: 100%"
                >
                  <div
                    style="width: 100%"
                    class="option"
                    v-for="(item, index) in ite.list"
                    :key="index"
                  >
                    <el-checkbox :label="item.sn" style="padding-top: 15px">
                      <div style="display: flex">
                        <span class="sn">{{ item.sn }}：</span>
                        <span
                          v-html="item.choiceContent"
                          class="span_p"
                        ></span></div
                    ></el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </el-scrollbar>
          </el-card>
        </el-scrollbar>
      </el-card>
    </el-card>
    <el-dialog
      title=" 溯源《标准答案》"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div v-for="(m, i) in TerrCon" :key="i">
        <span v-html="m.description"></span>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="onDialogVisible(2)">取 消</el-button>
        <el-button type="primary" @click="onDialogVisible(1)"
          >此为标准答案</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { previewGetTheme } from "../../../../api/api";
export default {
  data() {
    return {
      list: [],
      params: {},
      active: 0,
      taskAmount: null,
      count: 0,
      time: "00:00",
      descriptions: [],
      questions: [],
      selected: false,
      selecteObj: {},
      answer: {},
      dialogVisible: false,
      knowledge: [],
      totalTime: 0,
      descriptionType: this.parameter.descriptionType(),
      questionsType: {
        2: "单选题",
        3: "多选题",
      },
      userAnswerResultType: {
        0: "您的答案正确!",
        1: "您的答案错误!",
      },
      showButton: true,
      showNext: false,
      subjectId: 0,
      completionStatus: false,
      isComplete: 1,
      VariantName: "",
      TreepreviewId: null,
      treedata: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      TerrCon: [],
    };
  },
  mounted() {
    this.list = JSON.parse(this.$route.query.list);
    this.VariantName = this.$route.query.name;
    this.TreepreviewId = this.$route.query.Treepreview;
    this.descriptions = this.list[0].children;
    this.questions = this.list[1].children;
    previewGetTheme({ id: this.$route.query.Treepreview }).then((res) => {
      this.treedata.push(res.data);
    });
    //处理正确答案
    for (let i = 0; i < this.questions.length; i++) {
      if (this.questions[i].caseType == 2) {
        this.questions[i].userAnswer = [];
      } else {
        this.questions[i].userAnswer = "";
      }
      this.questions[i].list = this.questions[i].list.sort((a, b) => {
        return a.sn + "" > b.sn + "" ? 1 : -1;
      });
      this.questions[i].answer = this.questions[i].list.map((a, i) => {
        let str = null;
        a.isAnswer == 1 ? (str = a.sn) : (str = null);
        return str;
      });
      this.questions[i].answer = this.questions[i].answer.filter((item) => {
        return item !== null;
      });
    }
    this.questions.map((a, i) => {
      if (a.variantSubjectValue == 1) {
        a.answer = a.answer.toString();
      }
    });
  },
  components: {},

  methods: {
    onTree(node, data) {
      if (data.descriptions) {
        this.TerrCon = data.descriptions;
        this.dialogVisible = true;
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import "../style/preview.less";
.class3 {
  background: #d8e8ff !important;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.class4 {
  background: green;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.des /deep/ p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
}
.des/deep/img {
  vertical-align: text-top;
}

.stemContent/deep/img {
  vertical-align: middle;
}
.choices/deep/p {
  display: inline-block;
  & img {
    height: 100%;
    vertical-align: middle;
  }
}
.activity-button {
  width: 100% !important;
}
.wrong {
  border-top: 1px solid rgb(184, 184, 184);
  display: block;
  color: red;
  margin-top: 10px;
  div {
    display: inline-block;
  }
}
</style>
